import axios from 'axios';

const getFrameImagePath = async (jobNo, winNo) => {
  try {
    const res = await axios(
      `${process.env.REACT_APP_API_HOST}/Panel/frame-image?jobNo=${jobNo}&winNo=${winNo}`
    );

    return {
      success: res.data.success,
      path: res.data.path
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const getFrameDetail = async barcode => {
  try {
    const res = await axios(
      `${process.env.REACT_APP_API_HOST}/Panel/frame-detail?barcode=${barcode}`
    );

    return {
      message: 'Successful response',
      success: res.data.success,
      frameDetail: res.data.frameDetail
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const getFrameDetailV2 = async barcode => {
  try {
    const res = await axios(
      `${process.env.REACT_APP_API_HOST}/Panel/frame-detail-v2?barcode=${barcode}`
    );

    return {
      success: res.data.success,
      frameDetail: res.data.frameDetail
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const startScanFrame = async payload => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/EvaScanStation/frame/start`,
    method: 'POST',
    data: payload
  });

  try {
    return {
      success: res.data.updated,
      userTrackingId: res.data.userTrackingId,
      frameDetail: res.data.frameDetail,
      isForwardManu: res.data.isForwardManu,
      isNewAndCritical: res.data.isNewAndCritical
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const startScanFrameV2 = async payload => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/EvaScanStation/frame-v2/start`,
    method: 'POST',
    data: payload
  });

  try {
    return {
      success: res.data.updated,
      userTrackingId: res.data.userTrackingId,
      frameDetail: res.data.frameDetail,
      isForwardManu: res.data.isForwardManu,
      isNewAndCritical: res.data.isNewAndCritical,
      newAndCriticalType: res.data.newAndCriticalType
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const completeScanFrame = async payload => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/EvaScanStation/frame/complete`,
    method: 'POST',
    data: payload
  });

  try {
    return {
      completed: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const getFrameProductType = async barcode => {
  try {
    const res = await axios(
      `${process.env.REACT_APP_API_HOST}/QC/product-type?barcode=${barcode}`
    );

    return {
      success: res.data.success,
      productType: res.data.productType,
      headerId: res.data.headerId,
      frameId: res.data.frameId
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const getProductTypes = async () => {
  try {
    const res = await axios(
      `${process.env.REACT_APP_API_HOST}/QC/product-types`
    );

    return {
      data: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const getProducts = async () => {
  try {
    const res = await axios(`${process.env.REACT_APP_API_HOST}/QC/products`);

    return {
      data: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const getFaultGroups = async () => {
  try {
    const res = await axios(
      `${process.env.REACT_APP_API_HOST}/QC/fault-groups`
    );

    return {
      data: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const getFaultDescriptions = async () => {
  try {
    const res = await axios(
      `${process.env.REACT_APP_API_HOST}/QC/fault-descriptions`
    );

    return {
      data: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const addFaultList = async data => {
  try {
    const res = await axios({
      url: `${process.env.REACT_APP_API_HOST}/QC/fault-list`,
      method: 'POST',
      data: data
    });
    return {
      data: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const submitNCCheckSheet = async nCCheckSheets => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/QC/nc-check-sheets`,
    method: 'POST',
    data: nCCheckSheets
  });

  try {
    return {
      data: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const getFrameProductChecking = async barcode => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/EvaScanStation/nc-product-checking?barcode=${barcode}`,
    method: 'GET'
  });

  try {
    return {
      data: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const getHeaderIdAndFrameId = async (jobNo, winNo) => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/EvaScanStation/barcode-info?jobNo=${jobNo}&winNo=${winNo}`,
    method: 'GET'
  });

  try {
    return {
      data: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const startScanFrameV3 = async payload => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/EvaScanStation/frame-v3/start`,
    method: 'POST',
    data: payload
  });

  try {
    return {
      success: res.data.updated,
      userTrackingId: res.data.userTrackingId,
      frameDetail: res.data.frameDetail,
      isForwardManu: res.data.isForwardManu,
      isNewAndCritical: res.data.isNewAndCritical,
      image: res.data.image,
      isSash: res.data.isSash
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const getSize = async barcode => {
  const res = await axios(
    `${process.env.REACT_APP_API_HOST}/Panel/size?barcode=${barcode}`
  );

  try {
    return {
      success: res.success,
      data: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const getSashAllocator = async barcode => {
  const res = await axios(
    `${process.env.REACT_APP_API_HOST}/Panel/sash-allocator?barcode=${barcode}`
  );

  try {
    return {
      success: res.success,
      data: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

export {
  getFrameDetail,
  getFrameDetailV2,
  getFrameImagePath,
  startScanFrame,
  startScanFrameV2,
  completeScanFrame,
  getFrameProductType,
  getProductTypes,
  getProducts,
  getFaultGroups,
  getFaultDescriptions,
  addFaultList,
  submitNCCheckSheet,
  getFrameProductChecking,
  getHeaderIdAndFrameId,
  startScanFrameV3,
  getSize,
  getSashAllocator
};
